.contact__info {
    display: flex;
    gap: 2rem;
    justify-content: center;
    
}

.contact__info div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    width: 10rem;
}

.contact__info div a {
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .contact__info div {
        width: 5.2rem;
    }
    .contact__info div a,
    .contact__info div span {
        font-size: .6rem !important;
    }
}