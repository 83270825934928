@font-face {
	font-family: 'BiroScript';
	src: url('../fonts/Biro Script Bold Standard US.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'BiroScript';
	src: url('../fonts/Biro Script Bold Standard.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'BiroScript';
	src: url('../fonts/Biro Script Sloppy Standard US.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'BiroScript';
	src: url('../fonts/Biro Script Sloppy Standard.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'BiroScript';
	src: url('../fonts/Biro Script Standard US.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'BiroScript';
	src: url('../fonts/Biro Script Standard.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
