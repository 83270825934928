.book__table {
    min-width: 100%;
}

.book__table a {
    font-family: 'BiroScript', sans-serif;
    text-decoration: none;
    font-size: 3rem;
}

.book__link {   
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    transition: all .1s;
}

.book__link:hover {
    transform: translateY(-.1rem);
}

@media screen and (max-width: 768px) {
    .book__table a {
        font-size: 2.2rem;
    }

    .book__table img {
        height: 15px;
    }
}