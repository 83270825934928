@import './assets/css/fonts.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --bg-color: #FFFFFB;
    --text-black: #030303;
    --text-dark-gray: #444;
}

html {
    overflow-x: hidden;
    background: var(--bg-color) !important;
}

.container {
    max-width: 1280px;
    padding: 1rem 0rem;
    margin: 0 auto;
}

a {
    color: var(--text-black);
    font-family: 'montserrat', sans-serif;
}

a:hover {
    color: var(--text-dark-gray);
}

p, span, strong, em {
    font-family: 'montserrat', sans-serif;
}

.mb-0 {
    margin-block-end: 0;
    margin-bottom: 0;
}

main {
    max-height: 100svh !important;
    display: flex;
    flex-direction: column;
    height: 100svh;
    overflow-x: hidden;
}

.back__link {
    align-self: self-start;
}